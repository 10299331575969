<script>
	import router from "page";
	import Index from "./pages/index.svelte";
	import Pastebin from "./pages/pastebin.svelte";
	import Staff from "./pages/staff.svelte";

	let page;
	let props;

	router("/", () => page = Index);

	router("/pastebin/:uuid", (ctx) => {
		page = Pastebin;
		props = {uuid: ctx.params.uuid};
	});

	//router("/staff", () => page = Staff);
	
	router("*", () => page = Index);

	router.start();
</script>

<svelte:component this={page} {...props}></svelte:component>

<style>
	
</style>