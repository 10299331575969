<script>

</script>

<div class="container text-center mt-5">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <h1 class="display-2">AniRealm</h1>
        <p class="h3">
            The AniBot support server!
        </p>
    </div>

    <div class="mt-3 d-flex justify-content-center flex-row flex-wrap">
        <button class="button add-btn m-1">
            <i style="font-size: 25px;" class="fa-brands fa-discord"></i>
            <a style="-webkit-text-stroke: 0;" href="https://discord.com/invite/ddUT6DWytc" target="_blank">Join AniRealm!</a>
        </button>
        <button class="button srv-btn m-1">
            <i style="font-size: 25px;" class="fa-solid fa-link"></i>
            <a style="-webkit-text-stroke: 0;" href="https://anibot.moe/" target="_blank">Add AniBot!</a>
        </button>
    </div>

    <!-- <div class="mt-3 d-flex justify-content-center flex-row flex-wrap"> 
        <div>
            <a href="/staff" type="button" class="btn btn-outline-primary">Apply Staff</a>
        </div>
    </div> -->
</div>

<style>

</style>