<script>
    export let uuid;

    document.body.style.background = "none";
    document.body.style.backgroundColor = "#002b36";

    let pastebin = [];

    fetch(`/.netlify/functions/pastebin?uuid=${uuid}`)
    .then(res => res.json())
    .then(data => {
        pastebin = data.data;
    })
    .catch(() => {});
</script>

<div class="textBox d-flex flex-row flex-nowrap">
    {#if pastebin.length}
        <div id="lines">
            {#each [...Array(pastebin.length).keys()] as i}
                {i}
                {@html "<br>"}
            {/each}
        </div>
        <div id="box">
            <code>
                {#each pastebin as i}
                    {i}
                    {@html "<br>"}
                {/each}
            </code>
        </div>
    {:else}
        <div class="d-flex flex-column align-items-center justify-content-center mt-5 w-100">
            <h1 class="display-3">Invalid UUID</h1>
        </div>
    {/if}
</div>

<style>
    .textBox {
        background-color: #002b36;
        -webkit-text-stroke: 0;
    }

    #lines {
            color: #7d7d7d;
            top: 20px;
            left: 0;
            width: 25px;
            font-family: monospace;
            text-align: center;
            user-select: none;
        }

    #box {
            padding: 0;
            margin: 0;
            width: 100%;
            border: 0;
            outline: none;
            font-size: 16px;
            overflow: scroll;
            scrollbar-width: none;
        }

    #box code {
            padding: 0;
            background: 0 0 !important;
            white-space: nowrap;
        }
</style>